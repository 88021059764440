import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Praxis für ganzheitliche Medizin — Heilpraktikerin Petra Großfeld`}</h2>
    <p>{`Meine Homepage ist im Aufbau – ab Juli bin ich gerne für Sie da!`}</p>
    <h4>{`Meine naturheilkundlichen Leistungen für Sie:`}</h4>
    <ul>
      <li parentName="ul">{`Akupunktur`}</li>
      <li parentName="ul">{`Dorn/Breuß Wirbelsäulentherapie`}</li>
      <li parentName="ul">{`Ernährungsberatung`}</li>
      <li parentName="ul">{`Homöopathie`}</li>
      <li parentName="ul">{`Fußgesundheit/Fußpflege/Wundbehandlung`}</li>
      <li parentName="ul">{`Kinesiotaping`}</li>
      <li parentName="ul">{`Neuraltherapie`}</li>
      <li parentName="ul">{`Phytotherapie`}</li>
      <li parentName="ul">{`Schmerztherapie`}</li>
      <li parentName="ul">{`Stress- und Burnouttherapie`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      